import {motion} from "framer-motion";
import Icon from "../Icon";
import useDeviceInfo from "../../utils/hooks/useDeviceInfo";
import {useState} from "react";

const ModalSetup = ({active, onClose, isInstallable, handleInstall}) => {
  const {os, browser} = useDeviceInfo();

  const show = {
    opacity: 1,
    display: "flex",
  };

  const hide = {
    opacity: 0,
    transitionEnd: {
      display: "none"
    }
  };

  return (
    <>
      <motion.div
        animate={active ? show : hide}
        className={`modal modal_type-3 modal-settings modal-setup ${isInstallable?'modal-setup--installable':''} ${active ? 'modal--show' : ''}`}
      >
        <div className="modal__inner">

          <div className="modal__wrap">
            <div className="modal__header">
            <div onClick={onClose} className="modal__close modal-close">
              <Icon name="close"/>
            </div>
          </div>
            <div className="modal__content">
              <p className="modal-setup__btn-text">Для полноценного использования приложения, нажмите кнопку
                "установить"</p>
              <button className="button modal-setup__setup-btn" type="button" onClick={handleInstall}>
                Установить приложение
              </button>
            </div>
          </div>
        </div>
      </motion.div>
    </>
  )
}
export default ModalSetup

import {useEffect, useRef, useState} from "react";
import {sipConfig as config} from "../utils/consts";
import cn from 'classnames';
import {useSIPProvider, useSessionCall} from "react-sipjs";
import {SessionState} from "sip.js";
import CallActions from "../components/CallActions";
import {useNavigate, useSearchParams } from "react-router-dom";
import {useUserStore} from "../store/user-store";


const PageCall = () => {
  const [phone] = useUserStore((state) => [state.phone]);

  const [loaded, setLoaded] = useState(false);
  const [callTo, setCallTo] = useState('88100');
  const [sessionData, setSessionData] = useState(null);

  const refAudioRemote = useRef(null);

  const {
    connectAndRegister,
    sessionManager,
    sessions,
    registerStatus,
    connectStatus,
  } = useSIPProvider();

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const callNumber = searchParams.get("call_number");
    const type = searchParams.get("type");

    setCallTo(type === "callback" && callNumber ? callNumber : "88100");
  }, [searchParams]);


  useEffect(() => {
    console.log(callTo)
  }, [callTo]);


  useEffect(() => {
    connectAndRegister({
      username: '0100',
      password: config.password
    });
  }, [connectAndRegister]);

  useEffect(() => {
    Object.values(sessions).forEach((session) => {
      console.log(session)
      if (session.state === 'Terminated') {
        navigate('/main-auth');
      }
    })
  }, [sessionManager, sessions]);

  useEffect(() => {
    if (sessions) {
      Object.keys(sessions).map((sessionId) => (
        setSessionData(sessionId)
      ))
    }
  }, [sessions]);

  useEffect(() => {
    setLoaded(true);
  }, []);

  useEffect(() => {
    async function doCall() {
      if (sessionManager) {
        try {

          const userData = {
            phone: phone,
            // добавьте другие данные о пользователе, если необходимо
          };

          await sessionManager.call(`sip:${callTo}@${config.realm}`, {
            media: {
              remote: {
                audio: refAudioRemote.current
              }
            },
            extraHeaders: [
              `P-Asserted-Identity: <sip:${phone}@${config.realm}>`
            ],
            userData: userData // передаем дополнительные данные о пользователе
          });

        } catch (e) {
          console.log(e)
        }
      }
    }

    doCall();

  }, [sessionManager]);

  useEffect(() => {

    if (connectStatus === 'CONNECTED') {
      Object.values(sessions).forEach((session) => {
        if (session.state === 'established') {
          console.log(session)
        }
      })
    }

    if (connectStatus === 'DISCONNECTED') {
      navigate('/main-auth');
    }

  }, [connectStatus, sessions]);


  return (
    <div className={cn('page-loading-animation', {'on': loaded})}>
      <div className="call bg_3">
        <div className="call__info container">
          <div className="call__status">Соединение...</div>
          <div className="call__status">Connect Status: {connectStatus}</div>
          <div className="call__name">Горячая линия «Микрохирургия глаза»</div>
        </div>
        {sessionData !== null && connectStatus === 'CONNECTED' ?
          <CallActions sessionManager={sessionManager} sessions={sessions} sessionData={sessionData}/> : null}
      </div>
      <audio id={"call-audio"} ref={refAudioRemote}/>
    </div>
  );
}

export default PageCall;

import { useEffect, useState } from 'react';

const useDeviceInfo = () => {
  const [os, setOs] = useState('Android');
  const [browser, setBrowser] = useState('');

  useEffect(() => {
    const userAgent = navigator.userAgent;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      setOs('iOS');
    } else if (/Android/.test(userAgent)) {
      setOs('Android');
    } else {
      setOs('Android');
    }

    if (/CriOS/.test(userAgent)) {
      setBrowser('Chrome');
    } else if (/Chrome/.test(userAgent) && !/Edg/.test(userAgent)) {
      setBrowser('Chrome');
    } else if (/Safari/.test(userAgent) && !/Chrome/.test(userAgent)) {
      setBrowser('Safari');
    } else {
      setBrowser('Chrome');
    }
  }, []);

  return { os, browser };
};

export default useDeviceInfo;
import {motion} from "framer-motion";
import Icon from "../Icon";
import useDeviceInfo from "../../utils/hooks/useDeviceInfo";

const ModalSetupManual = ({active, onClose}) => {
  const {os, browser} = useDeviceInfo();

  const show = {
    opacity: 1,
    display: "flex",
  };

  const hide = {
    opacity: 0,
    transitionEnd: {
      display: "none"
    }
  };

  return (
    <>
      <motion.div
        animate={active ? show : hide}
        className={`modal modal_type-3 modal-settings modal-setup ${active ? 'modal--show' : ''}`}
      >
        <div className="modal__inner">

          <div className="modal__wrap">
            <div className="modal__header">
            <div onClick={onClose} className="modal__close modal-close">
              <Icon name="close"/>
            </div>
          </div>
            <div className="modal__content">
              <>
                <h2 className="modal-setup__title">Как установить приложение через браузер</h2>
                {/* Chrome Android */}
                {(os === "Android" && browser === "Chrome") && (
                  <ul className="modal-setup__grid">
                    <li className="modal-setup__grid-el">
                      <img src="/assets/images/modal-setup/chrome-android/1.png" alt=""
                           className="modal-setup__grid-img"/>
                      Откройте <a href="https://glmhg.ru" target="_blank">glmhg.ru</a> на вашем смартфоне в браузере
                      Google Chrome
                    </li>
                    <li className="modal-setup__grid-el">
                      <img src="/assets/images/modal-setup/chrome-android/2.png" alt=""
                           className="modal-setup__grid-img"/>
                      В верхнем меню браузера нажмите на кнопку с тремя точками
                    </li>
                    <li className="modal-setup__grid-el">
                      <img src="/assets/images/modal-setup/chrome-android/3.png" alt=""
                           className="modal-setup__grid-img"/>
                      В появившемся меню выберите «Добавить на гл. экран»
                    </li>
                    <li className="modal-setup__grid-el">
                      <img src="/assets/images/modal-setup/chrome-android/4.png" alt=""
                           className="modal-setup__grid-img"/>
                      Нажмите «Установить»
                    </li>
                    <li className="modal-setup__grid-el">
                      <img src="/assets/images/modal-setup/chrome-android/5.png" alt=""
                           className="modal-setup__grid-img"/>
                      Нажмите «Установить»
                    </li>
                    <li className="modal-setup__grid-el">
                      <img src="/assets/images/modal-setup/chrome-android/6.png" alt=""
                           className="modal-setup__grid-img"/>
                      Готово — иконка появится на рабочем столе
                    </li>
                  </ul>
                )}
                {/* Chrome iOS */}
                {(os === "iOS" && browser === "Chrome") && (
                  <ul className="modal-setup__grid">
                    <li className="modal-setup__grid-el">
                      <img src="/assets/images/modal-setup/chrome-ios/1.png" alt=""
                           className="modal-setup__grid-img"/>
                      Откройте <a href="https://glmhg.ru" target="_blank">glmhg.ru</a> на вашем смартфоне в браузере
                      Google Chrome
                    </li>
                    <li className="modal-setup__grid-el">
                      <img src="/assets/images/modal-setup/chrome-ios/2.png" alt=""
                           className="modal-setup__grid-img"/>
                      В адресной строке нажмите на кнопку «Поделиться»
                    </li>
                    <li className="modal-setup__grid-el">
                      <img src="/assets/images/modal-setup/chrome-ios/3.png" alt=""
                           className="modal-setup__grid-img"/>
                      В появившемся меню выберите «На экран “Домой”»
                    </li>
                    <li className="modal-setup__grid-el">
                      <img src="/assets/images/modal-setup/chrome-ios/4.png" alt=""
                           className="modal-setup__grid-img"/>
                      Нажмите «Добавить» в правом верхнем углу
                    </li>
                    <li className="modal-setup__grid-el">
                      <img src="/assets/images/modal-setup/chrome-ios/5.png" alt=""
                           className="modal-setup__grid-img"/>
                      Готово — иконка появится на рабочем столе
                    </li>
                  </ul>
                )}
                {/* Safari iOS */}
                {(os === "iOS" && browser === "Safari") && (
                  <ul className="modal-setup__grid">
                    <li className="modal-setup__grid-el">
                      <img src="/assets/images/modal-setup/safari-ios/1.png" alt=""
                           className="modal-setup__grid-img"/>
                      Откройте <a href="https://glmhg.ru" target="_blank">glmhg.ru</a> на вашем смартфоне в браузере
                      Safari
                    </li>
                    <li className="modal-setup__grid-el">
                      <img src="/assets/images/modal-setup/safari-ios/2.png" alt=""
                           className="modal-setup__grid-img"/>
                      Нажмите центральную иконку «Поделиться» в нижнем меню браузера
                    </li>
                    <li className="modal-setup__grid-el">
                      <img src="/assets/images/modal-setup/safari-ios/3.png" alt=""
                           className="modal-setup__grid-img"/>
                      В появившемся меню выберите «На экран “Домой”»
                    </li>
                    <li className="modal-setup__grid-el">
                      <img src="/assets/images/modal-setup/safari-ios/4.png" alt=""
                           className="modal-setup__grid-img"/>
                      Нажмите «Добавить» в правом верхнем углу
                    </li>
                    <li className="modal-setup__grid-el">
                      <img src="/assets/images/modal-setup/safari-ios/5.png" alt=""
                           className="modal-setup__grid-img"/>
                      Готово — иконка появится на рабочем столе
                    </li>
                  </ul>
                )}
              </>
            </div>
          </div>
        </div>
      </motion.div>
    </>
  )
}
export default ModalSetupManual

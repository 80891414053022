import {motion} from "framer-motion";
import Icon from "../Icon";

const ModalSettingsQr = ({active, qrUrl, onClose}) => {
  const show = {
    opacity: 1,
    display: "block",
  };
  const hide = {
    opacity: 0,
    transitionEnd: {
      display: "none"
    }
  };

  return (
    <motion.div
      animate={active ? show : hide}
      className={`modal modal_type-2 modal-settings modal-settings-qr ${active ? 'modal--show' : ''}`}
    >
      <div className="modal__inner">
        <div className="modal__wrap">
          <div onClick={onClose} className="modal__close modal-close">
            <Icon name="close"/>
          </div>
          <div className="modal__content">
            <div className="modal__header">
              <div className="modal__name">Ваш QR код</div>
              <div onClick={onClose} className="modal__close modal-close">
                <Icon name="close"/>
              </div>
            </div>
            <div className="modal__content modal-setting">
              {qrUrl && (
                <img
                  src={qrUrl}
                  className="modal__qr"
                  alt=""
                />
              )}
              {!qrUrl && (
                <h2 className="modal__qr-empty">Для вас нет QR кода</h2>
              )}
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  )
}
export default ModalSettingsQr
